.slide-in-right {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  animation: 0.3s ease-in slideInRight;
}

.slide-in-left {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  animation: 0.3s ease-in slideInLeft;
}

@keyframes slideInRight {
  0% {
    left: 100vw;
    opacity: 0;
  }

  75% {
    opacity: 1
  }

  100%{
    left: 0;
    opacity: 1;
  }
}

@keyframes slideInLeft {
  from {
    left: -100vw;
    opacity: 0;
  }

  to {
    left: 0;
    opacity: 1;
  }
}

@media only screen and (min-width: 600px) {
  @keyframes slideInRight {
    0% {
      left: 30px;
      opacity: 0;
    }

    75% {
      opacity: 1
    }

    100%{
      left: 0;
      opacity: 1;
    }
  }

  @keyframes slideInLeft {
    from {
      left: -30px;
      opacity: 0;
    }

    to {
      left: 0;
      opacity: 1;
    }
}

}